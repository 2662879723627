import * as React from "react"
import { useState, useEffect, useContext } from "react"
import { DataGrid } from "@mui/x-data-grid"
import Title from "./Title"
import axios from "axios"
import { Link as RouterLink, useNavigate } from "react-router-dom"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import AuthContext from "../../AuthContext"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { API_URL } from "../../config"

/** Full table for admin users
 * @param {string} title - Title of the table
 * @param {string} status - Status of the claims
 * @param {string} type - Type of the claims
 * @param {array} columns - Columns of the table
 * @returns {JSX.Element}
 */
export default function AdminFullTable({ title, status, type, columns }) {
  const [rows, setRows] = useState([])
  const [pageSize, setPageSize] = useState(10)
  const [search, setSearch] = useState("")
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [nextStepsOptions, setNextStepsOptions] = useState([])
  const [selectedNextStep, setSelectedNextStep] = useState("")
  const { token } = useContext(AuthContext)

  useEffect(() => {
    if (token) {
      let url = `${API_URL}/claims/all`
      const params = new URLSearchParams()
      if (status) params.append("status", status)
      if (type) params.append("type", type)
      url += `?${params.toString()}`

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setRows(response.data)

          // Get unique "Next Steps" values
          const uniqueNextSteps = Array.from(
            new Set(response.data.map((row) => row.next_step))
          )
          setNextStepsOptions(uniqueNextSteps)
        })
        .catch((error) => {
          console.error("Error fetching claims:", error)
        })
    } else {
      console.error("No token available")
    }
  }, [token, status, type])

  const handleSearchChange = (event) => {
    setSearch(event.target.value)
  }

  const handleDateChange = (newStartDate, newEndDate) => {
    setStartDate(newStartDate)
    setEndDate(newEndDate)
  }

  const handleClearFilters = () => {
    setSearch("")
    setStartDate(null)
    setEndDate(null)
    setSelectedNextStep("")
  }

  const handleNextStepChange = (event) => {
    setSelectedNextStep(event.target.value)
  }

  const navigate = useNavigate()

  const filteredRows = rows.filter((row) => {
    const matchesSearch =
      row.art_num.toLowerCase().includes(search.toLowerCase()) ||
      (row.customer_ref || "").toLowerCase().includes(search.toLowerCase()) ||
      row.acc_code.toLowerCase().includes(search.toLowerCase()) ||
      row.unique_ref.toLowerCase().includes(search.toLowerCase()) ||
      (row.scr || "").toLowerCase().includes(search.toLowerCase()) ||
      (row.pcr || "").toLowerCase().includes(search.toLowerCase())
    const matchesStartDate = startDate
      ? new Date(row.claim_date) >= new Date(startDate)
      : true
    const matchesEndDate = endDate
      ? new Date(row.claim_date) <= new Date(endDate)
      : true
    const matchesNextStep = selectedNextStep
      ? row.next_step === selectedNextStep
      : true
    return (
      matchesSearch && matchesStartDate && matchesEndDate && matchesNextStep
    )
  })

  return (
    <>
      <Box
        sx={{
          marginBottom: 2,
          display: "flex",
          gap: 2,
          justifyContent: "space-between",
        }}
      >
        <Title>{title}</Title>
        <Button
          variant="outlined"
          color="secondary"
          sx={{ color: "#F2AC00" }}
          onClick={handleClearFilters}
        >
          Clear
        </Button>
      </Box>
      <Box sx={{ marginBottom: 2, display: "flex", gap: 2 }}>
        <TextField
          label="Search"
          variant="outlined"
          value={search}
          onChange={handleSearchChange}
          fullWidth
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newValue) => handleDateChange(newValue, endDate)}
            renderInput={(params) => <TextField {...params} />}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(newValue) => handleDateChange(startDate, newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <Select
          value={selectedNextStep}
          onChange={handleNextStepChange}
          displayEmpty
        >
          <MenuItem value="">
            <em>All Next Steps</em>
          </MenuItem>
          {nextStepsOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <div style={{ height: 600, width: "100%" }}>
        <DataGrid
          style={{ cursor: "pointer" }}
          rows={filteredRows}
          columns={columns.map((col) => ({
            ...col,
          }))}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 25]}
          onRowClick={(params) => {
            navigate(
              `/claim?id=${
                params.row.unique_ref
              }&stage=${params.row.status.toLowerCase()}`
            )
          }}
          pagination
          checkboxSelection
          disableRowSelectionOnClick
          checkboxSelection={false}
        />
      </div>
    </>
  )
}
